import { Button, Form } from "react-bootstrap";
import UserActionPagesTemplate from "../../Template/UserActionPagesTemplate";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserLoginActionService } from "../../Services/UserAction/LoginActionService";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { SET_MESSAGE } from "../../Actions/Types";


const baseURL = `${process.env.REACT_APP_BASE_URL}`

// login page
const LoginPage = () => {

    // Redux Data
    const { user, websiteList } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Woocommerce Redirect Token
    const woocommerceToken = searchParams.get("token");
    

    //Get app parameter
    const app = searchParams.get("app");
    

    useEffect(() => {
        //console.info("This outside useEffect Call...");
        if (user && websiteList) {
            //console.info("This useEffect Call...");
            navigate('/front');
        }
    }, [user, websiteList]);

    // States
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [isFormAlert, setIsFormAlert] = useState<string>('');
    const [formAlertMessage, setFormAlertMessage] = useState('');

    // Focus Ref
    const emailRef = useRef<HTMLInputElement | null>(null);

    /* const handleFocus = () => {
        if (emailRef && emailRef.current) {
            emailRef.current.focus();
        }
    } */

    // function to handle form submit
    const HandleLoginFormSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();

        setIsFormAlert('');

        // checks if user email address not exist
        if (userEmail === '') {
            
            // dispatch error blank email error message 
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Enter Email and Password", { returnObjects: true }),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        } 
        // check if email address in correct format
        else if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(userEmail) === false) {
            
            // dispatch invalid error message 
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("valid email msg", { returnObjects: true }),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        } 
        // check if user password is empty
        else if (userPassword === '') {
            
            // dispatch blank password error message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("blank password msg", { returnObjects: true }),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        } 
        // check if user password length is not between 8 to 25
        else if (userPassword.length < 8 || userPassword.length > 25) {
            
            // dispatch invalid error message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("valid password msg", { returnObjects: true }),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        } else {
            // call Login action service with email, password, dispatch function, navigate function, t function and wooCommerce Token as parameter
            UserLoginActionService(userEmail, userPassword, dispatch, navigate, t, (woocommerceToken ? woocommerceToken : ``));
        }
    }


    return (
        <>
            <UserActionPagesTemplate>
                <>
                    <h1>{t('login form title')}</h1>
                    <Form>
                        <Form.Group className="form-group" controlId="user_email">
                            <Form.Label>{t('form email filed label')}</Form.Label>
                            <Form.Control type="email" size="lg" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserEmail(e.target.value)} value={userEmail} aria-required="true" autoComplete="email" ref={emailRef} />
                        </Form.Group>
                        <Form.Group className="form-group" controlId="user_password">
                            <Form.Label>{t('password placeholder label')}</Form.Label>
                            <Form.Control type="Password" size="lg" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserPassword(e.target.value)} value={userPassword} aria-describedby="passwordHelpBlock" aria-required="true" />
                            <Form.Text as={`div`} id="passwordHelpBlock" className="aioa_form-password-info-text">{t('valid password msg')}</Form.Text>
                        </Form.Group>
                        <Button variant="primary" type="submit" size="lg" className="w-100" onClick={HandleLoginFormSubmit}>{t('Login')}</Button>
                    </Form>

                    <div className="aioa_dashboard-login-link">
                        <Link to={`/front/forgot-password${(app && app === "readwritemadesimple") ? '?app=readwritemadesimple' : ''}`} className="aioa_forgot-password-link"> {t("lost password label")}</Link>
                        {(app && app === "readwritemadesimple") ? <></> : <a href={`${baseURL}/trial-subscription`} className="aioa_free-trial-link">{t('free trial btn label')}</a>}
                    </div>
                </>
            </UserActionPagesTemplate>

        </>
    )
}

export default LoginPage;