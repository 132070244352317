import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, InputGroup, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { SET_MESSAGE } from "../../Actions/Types";
import { domainValidationService } from "../../Services/DomainService/DomainValidationService";
import UpgradePlanList from "../PaymentButton/UpgradePlanList";
import SaveBulkDomain from "./AddBulkDomain";

interface PropTypes {
    isBulkDomainModalContent: boolean, // flag of multi-domain modal
}

// component to render add new domain
const AddNewNormalDomain = (props: PropTypes) => {

    const { isBulkDomainModalContent } = props;

    // Function
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Redux
    const { isLoading } = useSelector((store: StoreProptypes) => store);

    // State
    const [newAddWebsiteURL, setNewAddWebsiteURL] = useState<string>('');

    const [formStep01, setFormStep01] = useState<boolean>(true);
    
    const [formStep02, setFormStep02] = useState<boolean>(false);
    

    // function to handle validation of website url
    const handleCheckWebsiteValidation = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        console.log("newAddWebsiteURL", newAddWebsiteURL);
        // check if website url is empty
        if (newAddWebsiteURL === '') {
            // dispatch error message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Please add valid website URL'),
                    "toast_type": "danger"
                }
            })
        } else {
            // service to validate domain url
            domainValidationService((newAddWebsiteURL.replaceAll(" ", "").replace(/\s+/g, '')), dispatch, t).then((res) => {
                // check if response is ok
                if (res.states === 200) {
                    setFormStep02(!formStep02);
                    setFormStep01(!formStep01);
                }
            })
        }
    }

    return (
        <>

            {isBulkDomainModalContent ? (
                <>
                    <SaveBulkDomain />
                </>
            ) : (
                <>
                    <Form>
                        <div className="aioa_dashboard-multi-step-form-header">
                            <div className="aioa_dashboard-multi-step-form-header-line"></div>
                            <div className="aioa_dashboard-multi-step-form-step-list">
                                <div className={`form-step-number-box ${formStep01 && 'active'}`}>
                                    <div className="form-step-number" aria-hidden="true">1</div>
                                    <div className="form-step-name" aria-label={t("Step 1 of 2").toString()}> <span className="visually-hidden">{formStep01 && t("Current:")}</span> {t('Website Details')}</div>
                                </div>
                                <div className={`form-step-number-box ${formStep02 && 'active'}`}>
                                    <div className="form-step-number" aria-hidden="true">2</div>
                                    <div className="form-step-name" aria-label={t("Step 2 of 2").toString()}> <span className="visually-hidden">{formStep02 && t("Current:")}</span> {t('Plan Details')}</div>
                                </div>
                            </div>
                        </div>

                        <div aria-live="polite" className="aioa_dashboard-multi-step-form-step-content">

                            {formStep01 && (
                                <>
                                    <div className="aioa_dashboard-multi-step-form-title">
                                        <h2 className="mb-0 h5">{t('Website Details')}</h2>
                                    </div>

                                    <Form.Label htmlFor="url"> {t("Website URL")} <span className="text-danger" aria-hidden="true">*</span> </Form.Label>
                                    <InputGroup className="mb-3 input-group-outline">
                                        <Form.Control
                                            size="lg"
                                            id="url"
                                            type="text"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewAddWebsiteURL(e.target.value)}
                                            value={newAddWebsiteURL.replace(/\s+/g, '')}
                                            required
                                        />
                                    </InputGroup>
                                    

                                    <div className="d-flex justify-content-center">
                                        <Button className="aioa_dashboard-icon-btn icon-right" size="lg" onClick={(e) => handleCheckWebsiteValidation(e)} disabled={isLoading}>
                                            <div className="aioa_dashboard-btn-text">{t("Next")}</div>
                                            <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">arrow_right_alt</i></div>
                                        </Button>
                                    </div>
                                </>
                            )}


                            {formStep02 && (
                                <>
                                    <UpgradePlanList
                                        activePlanType={1}
                                        activePlanInterval={'M'}
                                        activePlanPrice={0}
                                        newDomain={newAddWebsiteURL.replaceAll(" ", "").replace(/\s+/g, '')}
                                        forAddNewDomain={true}
                                    />

                                    <div className="d-flex justify-content-center mt-3">

                                        <Button variant="secondary" className="aioa_dashboard-icon-btn" size="lg" onClick={(e) => { setFormStep02(!formStep02); setFormStep01(!formStep01); }}>
                                            <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">west</i></div>
                                            <div className="aioa_dashboard-btn-text">{t("Website Details")}</div>
                                        </Button>

                                    </div>
                                </>
                            )}
                        </div>
                    </Form>
                </>
            )}


        </>
    )
}
export default AddNewNormalDomain;