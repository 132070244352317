import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { getLanguagesList, setLanguage } from "../../Services/LanguageService";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../Assets/scss/components/language-button.scss";
import { TRANSLATION_LOADING } from "../../Actions/Types";
import { LanguagePropTypes } from "../../PropTypes/LanguagePropTypes";
import { getUserDetailsService } from "../../Services/UserAction/LoginActionService";
import { DomainDetailsService } from "../../Services/DomainService/DomainDetails";
import { getPlanListService } from "../../Services/PlanListService";

const DashboardLanguageButton = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    // Redux Store Selectors
    const languageList = useSelector((store: StoreProptypes) => store.languageList);
    const activeLanguage = useSelector((store: StoreProptypes) => store.activeLanguage);
    const activeLanguageKeywordsList = useSelector((store: StoreProptypes) => store.activeLanguageKeywordsList);
    const accessToken = useSelector((store: StoreProptypes) => store.accessToken);
    const currentWebsite = useSelector((store: StoreProptypes) => store.currentWebsite);

    // Component State
    const [showLanguageModal, setShowLanguageModal] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");

    // Open & Close Modal Functions
    const handleLanguageModalClose = () => setShowLanguageModal(false);
    const handleLanguageModalShow = () => setShowLanguageModal(true);

    // Fetch Languages List
    useEffect(() => {
        if (!languageList) {
            getLanguagesList(dispatch);
        }
    }, []);

    useEffect(() => {
        if (!activeLanguageKeywordsList) {
            getLanguagesList(dispatch);
        }
    }, []);

    // Handle Language Change
    i18n.on("languageChanged", () => {
        dispatch({ type: TRANSLATION_LOADING, payload: false });
    });

    useEffect(() => {
        if (activeLanguage) {
            const html = document.documentElement;
            const body = document.body;
            html.setAttribute("lang", activeLanguage.code);

            if (activeLanguage.is_rtl) {
                html.setAttribute("dir", "rtl");
                body.classList.add("rtl");
            } else {
                html.setAttribute("dir", "ltr");
                body.classList.remove("rtl");
            }
        }

        if (activeLanguage && activeLanguageKeywordsList) {
            if (!i18n.hasResourceBundle(activeLanguage.code, "translation")) {
                i18n.addResources(activeLanguage.code, "translation", { ...activeLanguageKeywordsList });
            }
            setTimeout(() => {
                i18n.changeLanguage(activeLanguage.code);
            }, 1000);
        }
    }, [activeLanguage, activeLanguageKeywordsList]);

    // Handle Search Input
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    // Transliteration Map for Phonetic Search Support
    const transliterationMap: Record<string, string> = {
        "हिन्दी": "hindi",
        "العربية": "arabic",
        "русский": "russian",
        "中文": "chinese",
        "日本語": "japanese",
        "한국어": "korean"
    };

    // Filter Function for Searching Languages
    const filterLanguages = (lang: LanguagePropTypes) => {
        const searchLower = searchQuery.toLowerCase();
        const langNameLower = lang.original_name.toLowerCase();
        const translitLangName = transliterationMap[lang.original_name] || "";

        return (
            langNameLower.includes(searchLower) ||
            translitLangName.includes(searchLower) ||
            lang.name.toLowerCase().includes(searchLower)
        );
    };

    // Apply Filtering to Language List
    const filteredLanguages = languageList?.filter(filterLanguages);

    return (
        <>
            {activeLanguage && (
                <Button className="aioa_dashboard-lang-btn" variant="outline-primary" onClick={handleLanguageModalShow}>
                    <div className="aioa_dashboard-lang-flag">
                        {activeLanguage.code.length > 2 ? activeLanguage.code.slice(-2) : activeLanguage.code}
                    </div>
                    <div className="aioa_dashboard-lang-name">{activeLanguage.original_name}</div>
                </Button>
            )}

            <Modal show={showLanguageModal} onHide={handleLanguageModalClose} size="lg" fullscreen="lg-down" backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Language Popup Title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Search Input */}
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder={t("Search Language")}
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </Form.Group>

                    <div className="aioa_dashboard-language-list">
                        <Row xl={2} className="g-4">
                            {filteredLanguages?.length ? (
                                filteredLanguages.map(lang => (
                                    <Col key={lang.id}>
                                        <Button
                                            onClick={() => setLanguage(lang, dispatch)}
                                            className={`aioa_dashboard-lang-btn ${activeLanguage?.id === lang.id ? "active" : ""}`}
                                            aria-pressed={activeLanguage?.id === lang.id ? "true" : "false"}
                                        >
                                            <div className="aioa_dashboard-lang-flag">
                                                {lang.code.length > 2 ? lang.code.slice(-2) : lang.code}
                                            </div>
                                            <div className="aioa_dashboard-lang-name">{lang.original_name}</div>
                                        </Button>
                                    </Col>
                                ))
                            ) : (
                                <p className="text-center">{t("No languages found")}</p>
                            )}
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DashboardLanguageButton;
